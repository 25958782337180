const FIVE_MINUTES_IN_MS = 5 * 60000;
const ONE_DAY_IN_MS = 24 * 60 * 60000;

export const validateSensoryDataStartEndTime = (start, end) => {
  // if end > start
  const startDate = new Date(start).getTime() / 1000;
  const endDate = new Date(end).getTime() / 1000;
  const diff = endDate - startDate; //seconds
  const fifteenMinutesInSeconds = 15 * 60;

  if (endDate < startDate || diff > fifteenMinutesInSeconds) {
    return { end: new Date().toISOString(), start: getNowMinusFiveMinutes() };
  }
  return { start, end };
};

export const getNowMinusFiveMinutes = () => {
  return new Date(new Date().getTime() - FIVE_MINUTES_IN_MS).toISOString();
};

export const getCurrentISOString = () => {
  return new Date().toISOString();
};

export const getSensoryDataStartAndEndTime = () => {
  const now = new Date();
  const start = new Date(now.getTime() - ONE_DAY_IN_MS).toISOString();
  const end = now.toISOString();
  return { start, end };
};
