import "../Header.css";
import { WasalineIcon } from "../../assets/WasalineIcon";

export const SplashHeader = () => (
  <div class="header">
    <div className="headerLeft">
      <WasalineIcon style={{ verticalAlign: "middle" }} />
    </div>
    <div class="headerMiddle splash">
      <div className="headerBorder" style={{ width: "51%" }} />
      <div class="headerMiddleContent">
        <div class="headerLeftBorder" />
        <b class="headerTitle splash">Aurora Botnia</b>
        <div class="headerRightBorder splash" />
      </div>
    </div>
  </div>
);
