import React from "react";
import "../../App.css";
import "../Header.css";
import wartsila from "../../assets/wartsila_new.png";

export const SplashBottomBar = () => {
  return (
    <div class="splashBottomBar">
      <div style={{ width: "34%" }} />
      <div class="splashBottomMiddle">
        <div class="headerBorder splash" />
        <h1 class="headerTitle" style={{ marginRight: 24, float: "right" }}>
          Powered by
        </h1>
      </div>
      <div style={{ width: "17%", display: "flex", zIndex: 2 }}>
        <div class="splashBottomRightBar">
          <img src={wartsila} width={82} alt="Wärtsilä icon" />
        </div>
      </div>
    </div>
  );
};
