import React, { Suspense, lazy } from "react";
import { ScreenClassProvider } from "react-grid-system";
import SplashScreen from "./components/Splash/Splash";
import Store from "./store/store";
const Main = lazy(() => import("./pages/Main"));

function App() {
  return (
    <ScreenClassProvider fallbackScreenClass="xxl">
      <Store>
        <Suspense fallback={<SplashScreen />}>
          <Main />
        </Suspense>
      </Store>
    </ScreenClassProvider>
  );
}

export default App;
