import React from "react";
export const WasalineIcon = (props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 231 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M133.576 27.4145C133.576 32.4619 129.749 36.4138 124.866 36.4138C119.983 36.4138 116.156 32.4619 116.156 27.4145C116.156 22.4061 119.983 18.4777 124.866 18.4777C129.749 18.4777 133.576 22.4061 133.576 27.4145ZM139.719 12.5303H133.842C133.505 12.5303 133.224 12.8042 133.224 13.1485V15.5823C130.923 13.2972 127.55 11.9591 124.052 11.9591C116.806 11.9591 109.105 17.3978 109.105 27.4849C109.105 37.2042 116.673 43.0107 123.989 43.0107C127.503 43.0107 130.891 41.5943 133.216 39.1919V41.8213C133.216 42.1578 133.49 42.4395 133.834 42.4395H139.703C140.04 42.4395 140.321 42.1656 140.321 41.8213V13.1485C140.329 12.8042 140.055 12.5303 139.719 12.5303Z"
        fill="#003764"
      />
      <path
        d="M96.7565 24.1356L96.6783 24.1199C92.4681 23.1809 90.2692 22.6174 90.2692 20.9819C90.2692 19.4794 91.6699 17.9534 94.8001 17.9534C97.3747 17.9534 99.5189 19.0099 100.841 20.935C101.021 21.201 101.366 21.2793 101.64 21.1306L106.507 18.4464C106.664 18.3604 106.773 18.2195 106.812 18.0473C106.851 17.8752 106.82 17.703 106.726 17.5543C104.269 13.8998 100.035 11.8886 94.808 11.8886C91.1456 11.826 87.9137 13.0312 85.7382 15.1988C84.0792 16.8422 83.1714 18.9316 83.1714 21.0993C83.1714 27.4458 88.5319 29.1126 93.5637 30.1769L94.2758 30.3256C97.4608 30.9751 100.215 31.5307 100.215 33.3306C100.215 35.0131 98.7207 36.7034 95.3949 36.7034C92.3899 36.7034 89.9796 35.3574 88.438 32.8219C88.2737 32.548 87.9137 32.4463 87.632 32.595L82.3575 35.3417C82.2089 35.42 82.0915 35.553 82.0445 35.7174C81.9976 35.8817 82.0211 36.0539 82.1071 36.2026C84.6974 40.6474 89.1971 42.9951 95.0975 42.9951C95.1445 42.9951 95.1914 42.9951 95.2305 42.9951C100.043 42.9951 102.892 41.1718 104.441 39.638C106.233 37.8616 107.258 35.5139 107.258 33.2132C107.258 26.9606 101.835 25.2468 96.7565 24.1356Z"
        fill="#003764"
      />
      <path
        d="M72.5756 27.4145C72.5756 32.4619 68.7489 36.4138 63.8658 36.4138C58.9827 36.4138 55.156 32.4619 55.156 27.4145C55.156 22.4061 58.9827 18.4777 63.8658 18.4777C68.7489 18.4777 72.5756 22.4061 72.5756 27.4145ZM78.7187 12.5303H72.8495C72.513 12.5303 72.2313 12.8042 72.2313 13.1485V15.5823C69.9306 13.2972 66.5578 11.9591 63.0598 11.9591C55.8134 11.9591 48.113 17.3978 48.113 27.4849C48.113 37.2042 55.6803 43.0107 62.9972 43.0107C66.5108 43.0107 69.8993 41.5943 72.2235 39.1919V41.8213C72.2235 42.1578 72.4974 42.4395 72.8417 42.4395H78.7108C79.0473 42.4395 79.3291 42.1656 79.3291 41.8213V13.1485C79.3369 12.8042 79.0552 12.5303 78.7187 12.5303Z"
        fill="#003764"
      />
      <path
        d="M48.2617 12.5303H42.2908C42.0247 12.5303 41.7899 12.7025 41.7039 12.9529L35.3182 32.1881L28.3222 12.9373C28.2361 12.6947 28.0014 12.5303 27.7431 12.5303H21.827C21.5688 12.5303 21.3418 12.6947 21.2479 12.9373L14.1502 32.1802L7.98368 12.9608C7.90542 12.7103 7.67065 12.5382 7.40459 12.5303L1.43371 12.4756H1.42589C1.23025 12.4756 1.04243 12.5695 0.925052 12.7338C0.807669 12.8903 0.776367 13.1016 0.838971 13.2894L10.3548 41.9935C10.4409 42.2439 10.6757 42.416 10.9417 42.416H16.7952C17.0456 42.416 17.2804 42.2595 17.3743 42.0169L24.7303 22.5548L31.9846 42.0169C32.0706 42.2595 32.3054 42.416 32.5637 42.416H38.2998C38.558 42.416 38.7928 42.2517 38.8788 42.0013L48.8486 13.3442C48.9112 13.1564 48.8877 12.9451 48.7703 12.7886C48.6529 12.6243 48.4651 12.5303 48.2617 12.5303Z"
        fill="#003764"
      />
      <path
        d="M206.642 23.893C207.934 20.4811 211.103 18.3056 214.828 18.3056C218.921 18.3056 221.816 20.3402 222.826 23.893H206.642ZM224.563 15.6684C221.135 12.7416 216.659 11.4582 212.269 12.1547C204.757 13.3442 199.302 19.8394 199.302 27.5945C199.302 36.5234 205.93 43.0029 215.055 43.0029C221.182 43.0029 226.112 40.1701 228.937 35.0287C229.102 34.7314 228.992 34.3557 228.695 34.1914L223.89 31.5151C223.749 31.4368 223.569 31.4133 223.413 31.4603C223.256 31.5072 223.115 31.6246 223.045 31.7733C221.503 34.8331 218.592 36.586 215.063 36.586C210.579 36.586 207.253 34.0975 206.283 30.0673H229.563C229.9 30.0673 230.182 29.7934 230.182 29.4491V27.8214C230.174 23.1418 228.131 18.7125 224.563 15.6684Z"
        fill="#003764"
      />
      <path
        d="M184.441 11.9512C181.084 11.9512 178.103 13.2033 175.966 15.504V13.1485C175.966 12.812 175.693 12.5303 175.348 12.5303H169.534C169.197 12.5303 168.916 12.8042 168.916 13.1485V41.8056C168.916 42.1421 169.19 42.4238 169.534 42.4238H175.403C175.74 42.4238 176.021 42.1499 176.021 41.8056V26.0215C176.021 21.3653 178.799 18.243 182.931 18.243C187.165 18.243 189.497 20.841 189.497 25.5598V41.7978C189.497 42.1343 189.771 42.416 190.115 42.416H196.047C196.383 42.416 196.665 42.1421 196.665 41.7978V25.0355C196.657 16.9674 191.977 11.9512 184.441 11.9512Z"
        fill="#003764"
      />
      <path
        d="M151.363 0.862476L145.768 3.08493C145.29 3.30404 145.15 3.64836 145.15 3.98486V41.8056C145.15 42.1421 145.423 42.4238 145.768 42.4238H151.645C151.981 42.4238 152.263 42.1499 152.263 41.8056V1.34766C152.263 1.01116 151.817 0.674664 151.363 0.862476Z"
        fill="#003764"
      />
      <path
        d="M163.563 12.5303H157.631C157.295 12.5303 157.013 12.8042 157.013 13.1486V41.8056C157.013 42.1421 157.287 42.4238 157.631 42.4238H163.563C163.9 42.4238 164.181 42.1499 164.181 41.8056V13.1486C164.181 12.8042 163.9 12.5303 163.563 12.5303Z"
        fill="#003764"
      />
      <path
        d="M160.613 9.62706C162.882 9.62706 164.721 7.78806 164.721 5.51866C164.721 3.24925 162.882 1.41026 160.613 1.41026C158.343 1.41026 156.504 3.24925 156.504 5.51866C156.504 7.78806 158.343 9.62706 160.613 9.62706Z"
        fill="#003764"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="229.366"
          height="42.1874"
          fill="white"
          transform="translate(0.807739 0.807693)"
        />
      </clipPath>
    </defs>
  </svg>
);
