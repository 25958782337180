import "../../App.css";
import { SplashHeader } from "./SplashHeader";
import { SplashBottomBar } from "./SplashBottomBar";
import { ReactComponent as FirstBird } from "../../assets/Splash/bird1.svg";
import { ReactComponent as SecondBird } from "../../assets/Splash/bird2.svg";
import { ReactComponent as ThirdBird } from "../../assets/Splash/bird3.svg";
import { ReactComponent as FourthBird } from "../../assets/Splash/bird4.svg";
import { ReactComponent as FifthBird } from "../../assets/Splash/bird5.svg";
import { Hidden, Visible } from "react-grid-system";
import { WasalineIcon } from "../../assets/WasalineIcon";

function SplashScreen() {
  return (
    <div class="splash">
      <Visible xs sm>
        <div
          style={{
            display: "flex",
            padding: "20vw",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            height: "100vh",
          }}
        >
          <WasalineIcon />
        </div>
      </Visible>
      <Hidden xs sm>
        <SplashHeader />
        <SplashBottomBar />
        <FirstBird
          style={{
            position: "absolute",
            left: "4.32%",
            right: "84.9%",
            top: "36.02%",
            bottom: "42.5%",
          }}
        />
        <SecondBird
          style={{
            position: "absolute",
            left: "4.84%",
            right: "88.12%",
            top: "13.8%",
            bottom: "72.69%",
          }}
        />
        <ThirdBird
          style={{
            position: "absolute",
            left: "28.54%",
            right: "64.43%",
            top: "19.35%",
            bottom: "62.59%",
          }}
        />
        <FourthBird
          style={{
            position: "absolute",
            left: "57.19%",
            right: "30.21%",
            top: "17.87%",
            bottom: "72.69%",
            zIndex: 3,
          }}
        />
        <FifthBird
          style={{
            position: "absolute",
            left: "86.46%",
            right: "5.42%",
            top: "2.5%",
            bottom: "77.41%",
            zIndex: 3,
          }}
        />
        <div class="splashbg" />
      </Hidden>
    </div>
  );
}
export default SplashScreen;
