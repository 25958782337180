const Reducer = (state, action) => {
  switch (action.type) {
    case "SET_SENSORS":
      return {
        ...state,
        sensors: action.payload,
      };
    case "SET_CURRENT_DATA":
      return {
        ...state,
        currentData: action.payload,
      };
    case "SET_VESSEL_DATA":
      return {
        ...state,
        vesselData: action.payload,
      };
    case "SET_ACTIVE_VIEW":
      return {
        ...state,
        activeView: action.payload,
      };
    case "SET_HAS_SIGNAL":
      return {
        ...state,
        hasSignal: action.payload,
      };
    case "SET_LAST_UPDATED":
      return {
        ...state,
        lastUpdated: action.payload,
      };
    default:
      return state;
  }
};

export default Reducer;
