import React, { createContext, useReducer } from "react";
import { getNowMinusFiveMinutes } from "../utils/time";
import Reducer from "./reducer";


const initialState = {
  sensors: [],
  currentData: [],
  vesselData: {
    latitude: 0,
    longitude: 0,
    speed: 0,
    nextPort: "VAASA",
    lastPort: "HOLMSUND",
    distanceToGo: 10,
    distanceTravelled: 0,
  },
  error: null,
  activeView: "main",
  hasSignal: true,
  lastUpdated: getNowMinusFiveMinutes(),
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);
export default Store;
